import React from 'react';
import { motion } from 'framer-motion';
import { Target } from 'lucide-react';
import { cn } from '../../utils/cn';

const Mission = () => {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className={cn(
        "relative group",
        "bg-gradient-to-br from-primary-900/30 via-primary-900/20 to-primary-900/10",
        "backdrop-blur-sm rounded-3xl p-12",
        "border border-primary-600/20",
        "hover:border-primary-600/40 transition-all duration-500",
        "shadow-lg shadow-primary-900/20",
        "hover:shadow-xl hover:shadow-primary-900/30"
      )}
    >
      {/* Background glow effect */}
      <div className="absolute inset-0 bg-gradient-to-br from-primary-500/5 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-500 rounded-3xl" />
      
      <div className="max-w-3xl mx-auto">
        {/* Icon and heading */}
        <div className="flex items-center justify-center gap-4 mb-8">
          <motion.div
            whileHover={{ rotate: 360 }}
            transition={{ duration: 0.8 }}
            className={cn(
              "p-4 rounded-2xl",
              "bg-primary-500/10",
              "border border-primary-500/20"
            )}
          >
            <Target className="w-8 h-8 text-primary-400" />
          </motion.div>
          <h3 className="text-3xl font-bold text-white">Our Mission</h3>
        </div>

        {/* Mission statement */}
        <p className={cn(
          "text-xl text-gray-300 text-center",
          "leading-relaxed tracking-wide",
          "group-hover:text-gray-200 transition-colors duration-300"
        )}>
          Our mission is to help businesses thrive by creating tailored, impactful digital solutions 
          that combine stunning design, innovative strategies, and measurable results.
        </p>
      </div>
    </motion.div>
  );
};

export default Mission;