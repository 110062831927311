import React from 'react';
import { motion } from 'framer-motion';
import { ExternalLink } from 'lucide-react';
import { Project } from '../../types';
import { cn } from '../../../../utils/cn';

interface ProjectContentProps {
  project: Project;
}

export const ProjectContent: React.FC<ProjectContentProps> = ({ project }) => {
  return (
    <div className="p-6 space-y-4">
      {/* Project Title with Link */}
      <a
        href={project.url}
        target="_blank"
        rel="noopener noreferrer"
        className="block group/title"
      >
        <h3 className={cn(
          "text-2xl font-bold",
          "text-white group-hover:text-primary-400",
          "transition-colors duration-300",
          "flex items-center gap-2"
        )}>
          {project.title}
          <ExternalLink className={cn(
            "w-5 h-5 opacity-0 group-hover/title:opacity-100",
            "transform group-hover/title:translate-x-1",
            "transition-all duration-300"
          )} />
        </h3>
      </a>
      
      {/* Project Description */}
      <p className={cn(
        "text-gray-300 group-hover:text-gray-200",
        "transition-colors duration-300",
        "line-clamp-3",
        "leading-relaxed"
      )}>
        {project.description}
      </p>

      {/* Technologies */}
      <div className="flex flex-wrap gap-2 pt-2">
        {project.technologies?.map((tech, index) => (
          <motion.span
            key={index}
            whileHover={{ y: -2 }}
            className={cn(
              "px-2.5 py-1 text-sm font-medium rounded-lg",
              "bg-primary-900/40 text-primary-300",
              "border border-primary-600/30",
              "transform transition-all duration-300",
              "hover:border-primary-600/50",
              "hover:shadow-lg hover:shadow-primary-900/20"
            )}
          >
            {tech}
          </motion.span>
        ))}
      </div>

      {/* Visit Button */}
      <motion.a
        href={project.url}
        target="_blank"
        rel="noopener noreferrer"
        className={cn(
          "inline-flex items-center mt-6 px-6 py-2.5",
          "bg-primary-600/20 hover:bg-primary-600/30",
          "text-primary-400 hover:text-primary-300",
          "rounded-lg border border-primary-600/30 hover:border-primary-600/50",
          "transition-all duration-300 group/button",
          "shadow-lg shadow-primary-900/10 hover:shadow-xl hover:shadow-primary-900/20"
        )}
        whileHover={{ scale: 1.02, y: -2 }}
        whileTap={{ scale: 0.98 }}
      >
        <span className="font-medium mr-2">Visit Project</span>
        <ExternalLink className={cn(
          "w-5 h-5 transform transition-transform duration-300",
          "group-hover/button:rotate-45 group-hover/button:translate-x-1"
        )} />
      </motion.a>
    </div>
  );
};