import React from 'react';
import { motion } from 'framer-motion';
import { Sparkles } from 'lucide-react';
import { cn } from '../../utils/cn';

const AboutHero = () => {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="relative max-w-4xl mx-auto text-center"
    >
      {/* Enhanced gradient background */}
      <div className="absolute inset-0 bg-gradient-radial from-primary-500/5 to-transparent opacity-50 blur-3xl -z-10" />
      
      {/* Subheading with icon */}
      <div className="flex items-center justify-center gap-3 mb-8">
        <Sparkles className="w-6 h-6 text-primary-400" />
        <h2 className="text-3xl font-bold text-primary-400">
          About ThirtyFive Studio
        </h2>
        <Sparkles className="w-6 h-6 text-primary-400" />
      </div>

      {/* Description with enhanced typography */}
      <p className={cn(
        "text-xl text-white/90 leading-relaxed",
        "mb-8 font-light tracking-wide"
      )}>
        At ThirtyFive Studio, we provide a full spectrum of digital solutions designed to help 
        businesses succeed in the modern online landscape. Our expertise spans website design 
        and development, app creation, branding, and digital transformation, ensuring your 
        business has everything it needs to thrive in the Minnesota market and beyond.
      </p>

      {/* Location badge */}
      <div className={cn(
        "inline-flex items-center px-6 py-3",
        "bg-primary-500/10 backdrop-blur-sm",
        "border border-primary-500/20",
        "rounded-full"
      )}>
        <span className="text-lg text-primary-300 font-medium">
          Serving Rochester, Minneapolis, St. Paul, and Greater Minnesota
        </span>
      </div>
    </motion.div>
  );
};

export default AboutHero;