import React from 'react';
import { motion } from 'framer-motion';

const HeroDescription = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ 
        duration: 0.8,
        delay: 0.8,
        ease: "easeOut"
      }}
      className="relative px-4"
    >
      <div className="absolute -inset-x-6 -inset-y-2 bg-gradient-to-r from-primary-500/0 via-primary-500/5 to-primary-500/0 blur-xl" />
      <p className="text-lg sm:text-xl md:text-2xl text-white/80 relative">
        Empowering Businesses with Tailored Digital Solutions
      </p>
    </motion.div>
  );
};

export default HeroDescription;