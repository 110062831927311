import React from 'react';
import { motion } from 'framer-motion';
import { LucideIcon } from 'lucide-react';
import { cn } from '../../utils/cn';

interface ServiceCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  image: string;
  features?: string[];
}

const ServiceCard = ({ icon: Icon, title, description, image, features }: ServiceCardProps) => {
  return (
    <motion.div
      whileHover={{ y: -8 }}
      className={cn(
        "group h-full max-w-sm mx-auto w-full",
        "relative p-6 rounded-3xl overflow-hidden",
        "bg-gradient-to-br from-dark-800/80 to-dark-900/90",
        "backdrop-blur-sm",
        "border border-primary-500/20",
        "transition-all duration-500",
        "hover:border-primary-500/40",
        "hover:shadow-xl hover:shadow-primary-500/10",
        "flex flex-col"
      )}
    >
      {/* Enhanced gradient background */}
      <div className="absolute inset-0 bg-gradient-to-br from-primary-500/5 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-all duration-500" />
      
      {/* Image container with fixed aspect ratio */}
      <div className="relative mb-4 h-40 overflow-hidden rounded-xl bg-dark-900/50">
        <img 
          src={image} 
          alt={title}
          className={cn(
            "w-full h-full object-cover",
            "transform transition-transform duration-700",
            "group-hover:scale-110"
          )}
          loading="lazy"
        />
        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-t from-dark-900/80 via-dark-900/20 to-transparent opacity-50" />
        
        {/* Icon overlay */}
        <div className="absolute bottom-4 right-4 p-3 bg-primary-500/20 backdrop-blur-sm rounded-xl border border-primary-500/30">
          <Icon className="w-6 h-6 text-primary-400" />
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 flex flex-col relative z-10">
        <h3 className="text-2xl font-bold text-white mb-4 group-hover:text-primary-300 transition-colors duration-300">
          {title}
        </h3>
        
        <p className="text-lg text-gray-300 leading-relaxed group-hover:text-gray-200 transition-colors duration-300 mb-6">
          {description}
        </p>

        {features && features.length > 0 && (
          <ul className="mt-auto space-y-2">
            {features.map((feature, index) => (
              <li 
                key={index}
                className="flex items-center text-gray-400 group-hover:text-gray-300 transition-colors duration-300"
              >
                <span className="w-1.5 h-1.5 bg-primary-400 rounded-full mr-2" />
                {feature}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Corner indicators */}
      <div className="absolute top-4 right-4 w-2 h-2 rounded-full bg-primary-400/30 opacity-0 group-hover:opacity-100 transition-all duration-300" />
      <div className="absolute bottom-4 left-4 w-2 h-2 rounded-full bg-primary-400/30 opacity-0 group-hover:opacity-100 transition-all duration-300" />
    </motion.div>
  );
};

export default ServiceCard;