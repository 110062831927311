import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Send } from 'lucide-react';
import { cn } from '../../utils/cn';
import SuccessAnimation from '../common/SuccessAnimation';

const ContactForm = () => {
  const [formState, setFormState] = useState({
    businessName: '',
    fullName: '',
    email: '',
    phone: '',
    topic: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      const response = await fetch('https://formspree.io/f/xovveglb', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(formState)
      });

      const data = await response.json();
      
      if (response.ok) {
        setIsSuccess(true);
        setFormState({
          businessName: '',
          fullName: '',
          email: '',
          phone: '',
          topic: '',
          message: ''
        });
      } else {
        throw new Error(data.error || 'Failed to submit form');
      }
    } catch (err) {
      setError('Failed to send message. Please try again.');
      console.error('Form submission error:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSuccess) {
    return <SuccessAnimation />;
  }

  return (
    <div className={cn(
      "relative p-8 rounded-3xl w-full",
      "bg-gradient-to-br from-dark-800/90 to-dark-900/90",
      "backdrop-blur-sm border border-primary-500/20",
      "hover:border-primary-500/40 transition-all duration-500",
      "shadow-lg shadow-primary-900/20"
    )}>
      <h2 className="text-3xl font-bold text-white mb-8">Send Us A Message</h2>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        {error && (
          <div className="p-4 bg-red-500/10 border border-red-500/20 rounded-lg">
            <p className="text-sm text-red-400">{error}</p>
          </div>
        )}

        <div className="grid md:grid-cols-2 gap-6">
          {/* Business Name */}
          <input
            type="text"
            name="businessName"
            placeholder="Business Name*"
            value={formState.businessName}
            onChange={handleChange}
            className={cn(
              "w-full px-4 py-3 rounded-lg",
              "bg-dark/30 backdrop-blur-sm",
              "border border-primary-600/20",
              "text-white placeholder-gray-400",
              "focus:border-primary-600/40 focus:ring-2 focus:ring-primary-600/20",
              "transition-all duration-300"
            )}
            required
          />

          {/* Full Name */}
          <input
            type="text"
            name="fullName"
            placeholder="Full Name*"
            value={formState.fullName}
            onChange={handleChange}
            className={cn(
              "w-full px-4 py-3 rounded-lg",
              "bg-dark/30 backdrop-blur-sm",
              "border border-primary-600/20",
              "text-white placeholder-gray-400",
              "focus:border-primary-600/40 focus:ring-2 focus:ring-primary-600/20",
              "transition-all duration-300"
            )}
            required
          />
        </div>

        <div className="grid md:grid-cols-2 gap-6">
          {/* Email */}
          <input
            type="email"
            name="email"
            placeholder="Email Address*"
            value={formState.email}
            onChange={handleChange}
            className={cn(
              "w-full px-4 py-3 rounded-lg",
              "bg-dark/30 backdrop-blur-sm",
              "border border-primary-600/20",
              "text-white placeholder-gray-400",
              "focus:border-primary-600/40 focus:ring-2 focus:ring-primary-600/20",
              "transition-all duration-300"
            )}
            required
          />

          {/* Phone */}
          <input
            type="tel"
            name="phone"
            placeholder="Phone Number*"
            value={formState.phone}
            onChange={handleChange}
            className={cn(
              "w-full px-4 py-3 rounded-lg",
              "bg-dark/30 backdrop-blur-sm",
              "border border-primary-600/20",
              "text-white placeholder-gray-400",
              "focus:border-primary-600/40 focus:ring-2 focus:ring-primary-600/20",
              "transition-all duration-300"
            )}
            required
          />
        </div>

        {/* Topic */}
        <div>
          <input
            type="text"
            name="topic"
            placeholder="What can we help you with?*"
            value={formState.topic}
            onChange={handleChange}
            className={cn(
              "w-full px-4 py-3 rounded-lg",
              "bg-dark/30 backdrop-blur-sm",
              "border border-primary-600/20",
              "text-white placeholder-gray-400",
              "focus:border-primary-600/40 focus:ring-2 focus:ring-primary-600/20",
              "transition-all duration-300"
            )}
            required
          />
        </div>

        {/* Message */}
        <div>
          <textarea
            name="message"
            placeholder="Tell us about what you are working on...*"
            value={formState.message}
            onChange={handleChange}
            rows={6}
            className={cn(
              "w-full px-4 py-3 rounded-lg",
              "bg-dark/30 backdrop-blur-sm",
              "border border-primary-600/20",
              "text-white placeholder-gray-400",
              "focus:border-primary-600/40 focus:ring-2 focus:ring-primary-600/20",
              "transition-all duration-300"
            )}
            required
          />
        </div>

        <motion.button
          type="submit"
          className={cn(
            "w-full py-4 rounded-full text-white text-lg font-medium",
            "bg-primary-600 hover:bg-primary-700",
            "transition-all duration-300",
            "disabled:opacity-50 disabled:cursor-not-allowed",
            "flex items-center justify-center gap-2"
          )}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.95 }}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <>
              <div className="w-5 h-5 border-2 border-white/80 border-t-transparent rounded-full animate-spin" />
              <span>Sending...</span>
            </>
          ) : (
            'Send Message'
          )}
        </motion.button>
      </form>
    </div>
  );
};

export default ContactForm;