import React from 'react';
import { cn } from '../../../utils/cn';
import { LogoSVG } from './LogoSVG';

interface LogoProps {
  className?: string;
  width?: number;
  height?: number;
}

const Logo: React.FC<LogoProps> = ({ 
  className,
  width = 446,
  height = 265
}) => {
  return (
    <div className={cn("inline-block", className)}>
      <LogoSVG width={width} height={height} />
    </div>
  );
};

export default Logo;