import React from 'react';
import { Heart, Target, Lightbulb, Users, Shield, HandshakeIcon } from 'lucide-react';
import { motion } from 'framer-motion';
import { cn } from '../../utils/cn';

const Values = () => {
  const values = [
    {
      icon: Heart,
      title: 'Passion for Excellence',
      description: 'We pour our heart into every project, ensuring exceptional quality and attention to detail.',
    },
    {
      icon: Target,
      title: 'Result-Driven',
      description: 'Our focus is on delivering measurable results that align with your business goals.',
    },
    {
      icon: Lightbulb,
      title: 'Innovative Solutions',
      description: 'We leverage cutting-edge technologies to create forward-thinking digital solutions.',
    },
    {
      icon: Users,
      title: 'Client-Centric',
      description: 'Your success is our priority. We work closely with you to understand and meet your needs.',
    },
    {
      icon: Shield,
      title: 'Trusted Partner',
      description: 'We build long-term relationships based on transparency, reliability, and trust.',
    },
    {
      icon: HandshakeIcon,
      title: 'Collaborative Approach',
      description: 'We work together with our clients to achieve the best possible outcomes.',
    },
  ];

  return (
    <div className="mt-16">
      <motion.h3 
        className="text-2xl font-bold text-center text-white mb-12"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        Our Core Values
      </motion.h3>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {values.map((value, index) => {
          const Icon = value.icon;
          return (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={cn(
                "group relative p-8 rounded-3xl",
                "bg-gradient-to-br from-dark-800/90 to-dark-900",
                "backdrop-blur-sm",
                "border border-primary-500/20",
                "transition-all duration-500",
                "hover:border-primary-500/40",
                "hover:shadow-xl hover:shadow-primary-500/10",
                "transform hover:translate-y-[-8px]"
              )}
            >
              {/* Subtle gradient background */}
              <div className="absolute inset-0 bg-gradient-to-br from-primary-500/5 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-all duration-500 rounded-3xl" />
              
              {/* Icon */}
              <div className="relative mb-6">
                <div className={cn(
                  "relative w-14 h-14 rounded-2xl",
                  "bg-dark-800/90",
                  "flex items-center justify-center",
                  "transform transition-all duration-500",
                  "group-hover:bg-primary-500/20"
                )}>
                  <Icon className="w-7 h-7 text-primary-300 group-hover:text-primary-200 transition-colors duration-300" />
                </div>
              </div>

              {/* Content */}
              <motion.h4 
                className="text-xl font-semibold text-white mb-3 group-hover:text-primary-300 transition-colors duration-300"
                whileHover={{ x: 4 }}
              >
                {value.title}
              </motion.h4>
              
              <p className="text-gray-300 leading-relaxed group-hover:text-gray-200 transition-colors duration-300">
                {value.description}
              </p>

              {/* Corner indicators */}
              <div className="absolute top-4 right-4 w-1 h-1 rounded-full bg-primary-400/30 opacity-0 group-hover:opacity-100 transition-all duration-300" />
              <div className="absolute bottom-4 left-4 w-1 h-1 rounded-full bg-primary-400/30 opacity-0 group-hover:opacity-100 transition-all duration-300" />
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default Values;