import React from 'react';
import { motion } from 'framer-motion';
import { Send, CheckCircle, Sparkles } from 'lucide-react';
import { cn } from '../../utils/cn';

const SuccessAnimation = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="relative p-8 rounded-3xl text-center py-12"
    >
      {/* Main Icon Animation */}
      <motion.div
        initial={{ scale: 0 }}
        animate={{ 
          scale: [0, 1.2, 1],
          rotate: [0, -10, 0]
        }}
        transition={{ 
          duration: 0.8,
          ease: "easeOut",
          times: [0, 0.6, 1]
        }}
        className="relative mx-auto mb-8 w-24 h-24"
      >
        {/* Outer Glow */}
        <motion.div
          className="absolute inset-0 bg-primary-500/20 rounded-full blur-2xl"
          animate={{
            scale: [1, 1.5, 1],
            opacity: [0.3, 0.8, 0.3]
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />

        {/* Icon Container */}
        <div className="relative h-full w-full">
          {/* Success Check */}
          <motion.div 
            className="absolute inset-0 flex items-center justify-center"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ 
              delay: 0.3,
              type: "spring",
              stiffness: 200
            }}
          >
            <CheckCircle className="w-12 h-12 text-primary-400" />
          </motion.div>

          {/* Envelope Animation */}
          <motion.div 
            className="absolute inset-0 flex items-center justify-center"
            initial={{ opacity: 1 }}
            animate={{ 
              opacity: 0,
              scale: [1, 1.5],
              rotate: [0, 15],
              y: -20
            }}
            transition={{
              duration: 0.5,
              ease: "easeOut",
              times: [0, 1]
            }}
          >
            <Send className="w-12 h-12 text-primary-400" />
          </motion.div>
        </div>

        {/* Sparkles */}
        {[...Array(3)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute"
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: [0, 1, 0],
              scale: [0.3, 1.2, 0.3],
              x: [0, (i - 1) * 30],
              y: [0, -20 + (i * 10)]
            }}
            transition={{
              duration: 1.2,
              delay: 0.2 + (i * 0.1),
              ease: "easeInOut"
            }}
            style={{
              left: '50%',
              top: '50%'
            }}
          >
            <Sparkles className="w-4 h-4 text-primary-300" />
          </motion.div>
        ))}
      </motion.div>

      {/* Success Message */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ 
          delay: 0.4,
          type: "spring",
          stiffness: 100
        }}
        className="relative"
      >
        <h4 className="text-2xl font-semibold text-primary-400 mb-4">
          Message Sent Successfully!
        </h4>
        <p className="text-gray-300">
          Thank you for reaching out. We'll review your project details and get back to you within 24 hours.
        </p>

        {/* Decorative Lines */}
        <motion.div
          className="absolute -inset-x-4 -inset-y-2 pointer-events-none"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          <div className="absolute inset-0 border-2 border-primary-500/10 rounded-xl" />
          <div className="absolute inset-0 border-2 border-primary-500/5 rounded-xl transform rotate-3" />
        </motion.div>
      </motion.div>

      {/* Background Particles */}
      {[...Array(6)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute w-1 h-1 bg-primary-400/30 rounded-full"
          animate={{
            y: [0, -10, 0],
            opacity: [0.3, 0.6, 0.3],
            scale: [1, 1.2, 1]
          }}
          transition={{
            duration: 2,
            delay: i * 0.2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          style={{
            left: `${20 + (i * 12)}%`,
            top: `${80 - (i * 10)}%`
          }}
        />
      ))}
    </motion.div>
  );
};

export default SuccessAnimation;