import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import { cn } from '../../utils/cn';

interface ParticlesOverlayProps {
  quantity?: number;
  color?: string;
  size?: 'sm' | 'md' | 'lg';
  speed?: 'slow' | 'normal' | 'fast';
}

const ParticlesOverlay: React.FC<ParticlesOverlayProps> = ({
  quantity = 30,
  color = 'primary-400',
  size = 'md',
  speed = 'normal'
}) => {
  const sizeClasses = {
    sm: 'w-1 h-1',
    md: 'w-1.5 h-1.5',
    lg: 'w-2 h-2'
  };

  const speedConfig = {
    slow: { min: 15, max: 25 },
    normal: { min: 10, max: 15 },
    fast: { min: 5, max: 10 }
  };

  const particles = useMemo(() => {
    return Array.from({ length: quantity }).map((_, i) => ({
      id: i,
      x: Math.random() * 100,
      y: Math.random() * 100,
      duration: speedConfig[speed].min + Math.random() * (speedConfig[speed].max - speedConfig[speed].min)
    }));
  }, [quantity, speed]);

  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      {particles.map((particle) => (
        <motion.div
          key={particle.id}
          className={cn(
            "absolute rounded-full",
            sizeClasses[size],
            `bg-${color}/30`
          )}
          initial={{
            x: `${particle.x}%`,
            y: `${particle.y}%`,
            opacity: 0.2,
            scale: 1
          }}
          animate={{
            x: [`${particle.x}%`, `${(particle.x + 50) % 100}%`, `${particle.x}%`],
            y: [`${particle.y}%`, `${(particle.y + 50) % 100}%`, `${particle.y}%`],
            opacity: [0.2, 0.5, 0.2],
            scale: [1, 1.2, 1]
          }}
          transition={{
            duration: particle.duration,
            repeat: Infinity,
            ease: "linear"
          }}
        />
      ))}
    </div>
  );
};

export default ParticlesOverlay;