import React from 'react';
import { motion } from 'framer-motion';
import { Code2, Globe } from 'lucide-react';
import { cn } from '../../../../utils/cn';
import { ProjectCategory } from '../../types';

interface ProjectImageProps {
  image: string;
  title: string;
  category: ProjectCategory;
  featured?: boolean;
}

export const ProjectImage: React.FC<ProjectImageProps> = ({ 
  image, 
  title, 
  category,
  featured
}) => {
  return (
    <div className="relative aspect-video overflow-hidden">
      {/* Image */}
      <div className="absolute inset-0">
        <img
          src={image}
          alt={title}
          className={cn(
            "w-full h-full object-cover object-center",
            "transform transition-all duration-700",
            "group-hover:scale-110",
            "filter brightness-105 contrast-105"
          )}
          loading="lazy"
        />
      </div>

      {/* Gradient overlay */}
      <div className={cn(
        "absolute inset-0 z-10",
        "bg-gradient-to-t from-dark via-dark/30 to-transparent",
        "opacity-60 group-hover:opacity-40",
        "transition-opacity duration-300"
      )} />
      
      {/* Category badge */}
      <div className="absolute top-4 left-4 z-20 flex items-center space-x-2">
        <motion.div
          whileHover={{ scale: 1.05 }}
          className={cn(
            "inline-flex items-center px-3 py-1.5 rounded-lg",
            "bg-primary-600/90 backdrop-blur-sm",
            "border border-primary-400/20",
            "shadow-lg shadow-primary-900/20"
          )}
        >
          {category === 'Mobile App' ? (
            <Code2 className="w-4 h-4 mr-2 text-white" />
          ) : (
            <Globe className="w-4 h-4 mr-2 text-white" />
          )}
          <span className="text-sm font-medium text-white">
            {category}
          </span>
        </motion.div>

        {featured && (
          <motion.div
            whileHover={{ scale: 1.05 }}
            className={cn(
              "px-3 py-1.5 rounded-lg",
              "bg-primary-400/90 backdrop-blur-sm",
              "border border-primary-300/20",
              "shadow-lg shadow-primary-900/20"
            )}
          >
            <span className="text-sm font-medium text-white">
              Featured
            </span>
          </motion.div>
        )}
      </div>
    </div>
  );
};