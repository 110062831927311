import React from 'react';
import BackgroundComposer from '../background/BackgroundComposer';
import HeroContent from './HeroContent';
import TrustedBy from '../TrustedBy';

const Hero = () => {
  return (
    <section id="home" className="relative min-h-screen flex flex-col bg-dark-900 overflow-hidden">
      <BackgroundComposer intensity="high" colorMode="default">
        {/* Main Content */}
        <div className="flex-1 flex flex-col items-center justify-start pt-32 md:pt-40">
          <div className="container mx-auto px-4 text-center mb-16">
            <HeroContent />
          </div>
          
          {/* Trusted By section integrated into hero */}
          <div className="w-full mt-12 md:mt-16">
            <TrustedBy />
          </div>
        </div>
      </BackgroundComposer>
    </section>
  );
};

export default Hero;