import React from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';
import { motion } from 'framer-motion';
import { cn } from '../../utils/cn';

const ContactInfo = () => {
  const contactDetails = [
    {
      icon: Mail,
      title: 'Email',
      content: 'info@thirtyfivestudio.com',
      href: 'mailto:info@thirtyfivestudio.com'
    },
    {
      icon: Phone,
      title: 'Phone',
      content: '+1 (651) 219-8788',
      href: 'tel:+16512198788'
    },
    {
      icon: MapPin,
      title: 'Service Areas',
      content: 'Rochester, MN | Minneapolis, MN | St. Paul, MN'
    }
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={cn(
        "relative p-8 rounded-3xl",
        "bg-gradient-to-br from-white/[0.08] via-white/[0.04] to-transparent",
        "backdrop-blur-sm border border-primary-500/20",
        "hover:border-primary-500/40 transition-all duration-500",
        "shadow-lg shadow-primary-900/10 hover:shadow-xl hover:shadow-primary-900/20"
      )}
    >
      <h3 className="text-2xl font-bold text-white mb-8">Contact Information</h3>
      
      <div className="space-y-8">
        {contactDetails.map((item, index) => (
          <div key={index}>
            <div className="flex items-center text-primary-400 mb-2">
              <item.icon className="w-5 h-5 mr-2" />
              <span>{item.title}</span>
            </div>
            {item.href ? (
              <a 
                href={item.href}
                className="text-lg hover:text-primary-400 transition-colors"
              >
                {item.content}
              </a>
            ) : (
              <p className="text-lg">{item.content}</p>
            )}
          </div>
        ))}
      </div>

      <div className="mt-12 p-8 bg-primary-500/10 rounded-2xl border border-primary-500/20">
        <h3 className="text-xl font-bold text-white mb-4">Ready to Get Started?</h3>
        <p className="text-gray-300 mb-6">
          Let's discuss how we can help transform your digital presence and drive growth for your business.
        </p>
        <motion.button
          onClick={() => window.open('https://calendly.com/thirtyfivestudio', '_blank')}
          className={cn(
            "px-8 py-4 rounded-full",
            "bg-primary-500 text-white",
            "hover:bg-primary-600 transition-all duration-300",
            "transform hover:translate-y-[-2px]",
            "shadow-lg shadow-primary-900/20",
            "hover:shadow-xl hover:shadow-primary-900/30"
          )}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Schedule a Call
        </motion.button>
      </div>
    </motion.div>
  );
};

export default ContactInfo;