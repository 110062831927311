import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import { motion } from 'framer-motion';
import { cn } from '../utils/cn';
import { Logo } from './common/Logo';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const navigation = [
    { name: 'Home', href: '#home' },
    { name: 'About', href: '#about' },
    { name: 'Services', href: '#services' },
    { name: 'Portfolio', href: '#portfolio' },
    { name: 'Contact', href: '#contact' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavClick = (href: string) => {
    setIsMenuOpen(false);
    const element = document.querySelector(href);
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <header 
      className={cn(
        "fixed w-full z-50 transition-all duration-500",
        isScrolled 
          ? "bg-dark-800/80 backdrop-blur-md border-b border-primary-500/10 py-2" 
          : "bg-transparent py-4"
      )}
    >
      <div className="container mx-auto px-4">
        <nav className="flex items-center justify-between">
          {/* Logo */}
          <motion.a 
            href="#home"
            className="flex items-center group"
            whileHover={{ scale: 1.05 }}
          >
            <Logo width={80} height={48} className="transition-transform duration-300 group-hover:scale-105" />
          </motion.a>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-1">
            {navigation.map((item) => (
              <motion.button
                key={item.name}
                onClick={() => handleNavClick(item.href)}
                className={cn(
                  "px-4 py-2 text-sm font-medium",
                  "text-white/80 hover:text-white",
                  "rounded-full",
                  "transition-all duration-300",
                  "hover:bg-primary-500/10",
                  "relative after:absolute after:inset-x-4 after:bottom-1.5 after:h-px",
                  "after:bg-primary-500 after:scale-x-0 hover:after:scale-x-100",
                  "after:transition-transform after:duration-300 after:origin-center"
                )}
                whileHover={{ y: -2 }}
              >
                {item.name}
              </motion.button>
            ))}
            <motion.button
              onClick={() => handleNavClick('#contact')}
              className={cn(
                "ml-4 px-6 py-2",
                "bg-primary-500 text-white rounded-full",
                "hover:bg-primary-600 transition-all duration-300",
                "transform hover:scale-105 active:scale-95",
                "shadow-md hover:shadow-lg hover:shadow-primary-500/20",
                "border border-primary-500/20"
              )}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Get Started
            </motion.button>
          </div>

          {/* Mobile Menu Button */}
          <button
            className={cn(
              "md:hidden p-2 rounded-full",
              "text-white hover:bg-primary-500/10",
              "transition-all duration-300",
              "border border-primary-500/20"
            )}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          >
            {isMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        </nav>

        {/* Mobile Navigation */}
        <motion.div 
          className={cn(
            "md:hidden fixed inset-x-0 top-[65px] p-4",
            "bg-dark-800/90 backdrop-blur-md",
            "border-b border-primary-500/10"
          )}
          initial={{ opacity: 0, y: -10 }}
          animate={{ 
            opacity: isMenuOpen ? 1 : 0,
            y: isMenuOpen ? 0 : -10,
            display: isMenuOpen ? 'block' : 'none'
          }}
          transition={{ duration: 0.2 }}
        >
          <div className="flex flex-col space-y-1">
            {navigation.map((item) => (
              <button
                key={item.name}
                onClick={() => handleNavClick(item.href)}
                className={cn(
                  "px-4 py-3 rounded-full text-left",
                  "text-white/80 hover:text-white",
                  "hover:bg-primary-500/10",
                  "transition-all duration-300",
                  "relative after:absolute after:inset-x-4 after:bottom-2",
                  "after:h-px after:bg-primary-500",
                  "after:scale-x-0 hover:after:scale-x-100",
                  "after:transition-transform after:duration-300"
                )}
              >
                {item.name}
              </button>
            ))}
            <button
              onClick={() => handleNavClick('#contact')}
              className={cn(
                "mt-4 px-6 py-3",
                "bg-primary-500 text-white rounded-full",
                "hover:bg-primary-600 transition-all duration-300",
                "border border-primary-500/20"
              )}
            >
              Get Started
            </button>
          </div>
        </motion.div>
      </div>
    </header>
  );
};

export default Header;