import { useState, useCallback, useMemo } from 'react';
import { projects } from '../data/projects';
import { ProjectCategory } from '../types';

export const usePortfolioFilter = () => {
  const [activeFilter, setActiveFilter] = useState<ProjectCategory>('All');

  const handleFilterChange = useCallback((filter: ProjectCategory) => {
    setActiveFilter(filter);
  }, []);

  const filteredProjects = useMemo(() => {
    if (activeFilter === 'All') {
      return projects;
    }
    return projects.filter(project => project.category === activeFilter);
  }, [activeFilter]);

  return {
    activeFilter,
    filteredProjects,
    handleFilterChange
  };
};