import { Project } from '../types';

export const projects: Project[] = [
  {
    id: '1',
    title: '35 Partners',
    category: 'Website',
    description: '35 Partners is a professional consulting firm dedicated to helping small businesses and early-stage start-ups scale and thrive. They offer a range of tailored services, including idea evaluation, consulting, operations improvement, marketing strategy, and digital transformation.',
    image: 'https://i.postimg.cc/Yjnx3MPc/35partners.png',
    url: 'https://www.35partners.com/',
    technologies: ['React', 'Tailwind CSS', 'Node.js']
  },
  {
    id: '2',
    title: 'OnWay Bookings',
    category: 'Website',
    description: 'Streamline operations, manage appointments, process payments, and boost visibility to nearby customers. A comprehensive platform connecting skilled professionals with clients through advanced digital solutions.',
    image: 'https://i.postimg.cc/JG0pVGX4/onway.png',
    url: 'https://www.onwaybookings.com/',
    technologies: ['React', 'Node.js', 'MongoDB']
  },
  {
    id: '3',
    title: 'My Home Care, Inc.',
    category: 'Website',
    description: 'My Home Care, Inc. is committed to enhancing lives by delivering personalized, compassionate care. Their mission focuses on ensuring individuals and families experience dignity, comfort, and independence through high-quality care services.',
    image: 'https://i.postimg.cc/JyMJ82Fc/myhomecare.png',
    url: 'https://myhomecareinc.org/',
    technologies: ['React', 'Tailwind CSS', 'Node.js']
  },
  {
    id: '4',
    title: 'ViewPoints',
    category: 'Mobile App',
    description: 'ViewPoints is a mobile app designed to keep you connected to your community. It provides real-time updates on local events, such as bars, sports games, parks, and malls, while letting you know how many people are there and what they\'re saying.',
    image: 'https://i.postimg.cc/G8Sskhmq/viewpoints.png',
    url: 'https://apps.apple.com/us/app/viewpoints/id1077165555',
    technologies: ['React Native', 'Firebase', 'Node.js']
  },
  {
    id: '5',
    title: 'Express Movers',
    category: 'Website',
    description: 'Express Movers offers stress-free, all-encompassing moving and cleaning services. They are a trusted partner for individuals and businesses, providing reliable and efficient solutions to meet moving and cleaning needs.',
    image: 'https://i.postimg.cc/rz3rsmbp/express.png',
    url: 'https://www.servicesbyexpress.com',
    technologies: ['React', 'Tailwind CSS', 'Node.js']
  },
  {
    id: '6',
    title: 'MaxOut Pro',
    category: 'Mobile App',
    description: 'MaxOut Pro is a fitness tracking app that helps you plan workouts, track progress, and achieve fitness goals. It allows users to log workout history, monitor personal records (PRs), set goals, and analyze stats for improved performance.',
    image: 'https://i.postimg.cc/CBCkFhnF/maxoutpro.png',
    url: 'https://maxoutpro.vercel.app/#',
    technologies: ['React Native', 'Firebase', 'Node.js']
  }
];