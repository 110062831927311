import { Globe, Wrench, Palette, Smartphone, Megaphone, Laptop } from 'lucide-react';
import { LucideIcon } from 'lucide-react';

interface ServiceData {
  icon: LucideIcon;
  title: string;
  description: string;
  features?: string[];
  image: string;
}

export const servicesData: ServiceData[] = [
  {
    icon: Globe,
    title: 'Website Design & Development',
    description: 'We craft visually stunning, responsive websites tailored to your brand\'s identity, delivering exceptional performance and an outstanding user experience.',
    features: ['Custom Design', 'Responsive Development', 'SEO Optimization', 'Performance Tuning'],
    image: 'https://i.postimg.cc/G2B211SF/99770c21d58f4ff3cf2eb39775f0c10a.png'
  },
  {
    icon: Wrench,
    title: 'Website Maintenance & Support',
    description: 'Keep your website running smoothly with ongoing updates, unlimited edits, and 24/7 support, ensuring optimal functionality at all times.',
    features: ['24/7 Support', 'Regular Updates', 'Security Monitoring', 'Performance Optimization'],
    image: 'https://i.postimg.cc/25QxmcT3/developer-reviewing-javascript.jpg'
  },
  {
    icon: Palette,
    title: 'Logo Design & Branding',
    description: 'Create a professional logo and cohesive visual identity that captures your brand\'s essence and resonates with your audience.',
    features: ['Logo Design', 'Brand Guidelines', 'Visual Identity', 'Brand Strategy'],
    image: 'https://i.postimg.cc/mgjdMx2W/Screenshot-2024-12-26-at-8-22-16-PM.png'
  },
  {
    icon: Smartphone,
    title: 'App Development',
    description: 'Need an app for your business or help with an existing one? We create custom mobile and web apps that enhance customer engagement, streamline operations, and support business growth.',
    features: ['Native Apps', 'Cross-platform', 'UI/UX Design', 'App Store Optimization'],
    image: 'https://i.postimg.cc/Ss6Y24kW/sumup-HNUWwu-E50-Bo-unsplash.jpg'
  },
  {
    icon: Megaphone,
    title: 'Digital Marketing Strategy',
    description: 'Maximize your brand\'s market presence with expertly crafted strategies in branding, advertising, and social media management, ensuring impactful engagement and growth.',
    features: ['Social Media', 'Content Strategy', 'Analytics', 'Campaign Management'],
    image: 'https://i.postimg.cc/L6t0f3Gz/tech-meeting-flatlay.jpg'
  },
  {
    icon: Laptop,
    title: 'Digital Transformation',
    description: 'We integrate digital technology into every aspect of your business, streamlining operations, enabling seamless payment processing, and transforming workflows.',
    features: ['Process Automation', 'Cloud Integration', 'Digital Workflows', 'Data Analytics'],
    image: 'https://i.postimg.cc/sfk4LGpT/sumup-LHqr-GYz-CXyo-unsplash.jpg'
  }
];