import React from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';
import { cn } from '../../utils/cn';

interface PricingPackageProps {
  title: string;
  price: string;
  description: string;
  features: string[];
  popular?: boolean;
  actionLabel?: string;
  actionUrl?: string;
}

const PricingPackage: React.FC<PricingPackageProps> = ({
  title,
  price,
  description,
  features,
  popular,
  actionLabel = "Start Your Project",
  actionUrl = "#contact"
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      whileHover={{ y: -8, scale: 1.02 }}
      className={cn(
        "group relative p-8 rounded-3xl overflow-visible",
        "bg-gradient-to-br from-white/[0.08] via-white/[0.04] to-transparent",
        "backdrop-blur-sm",
        "transition-all duration-500",
        "shadow-lg shadow-primary-900/10",
        "hover:shadow-xl hover:shadow-primary-900/20",
        popular ? "border-2 border-primary-500 mt-6" : "border border-primary-500/20"
      )}
    >
      {popular && (
        <motion.div 
          initial={{ y: -10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="absolute -top-6 inset-x-0 flex justify-center"
        >
          <span className="bg-primary-500 text-white px-6 py-2 rounded-full text-sm font-medium shadow-lg">
            Most Popular
          </span>
        </motion.div>
      )}

      <div className="space-y-8">
        {/* Package Header */}
        <div>
          <h3 className="text-2xl font-bold text-white mb-2">{title}</h3>
          <div className="mb-4">
            <span className="text-4xl font-bold text-primary-400">${price}</span>
          </div>
          <p className="text-gray-300">{description}</p>
        </div>

        {/* Features List */}
        <ul className="space-y-3">
          {features.map((feature, index) => (
            <motion.li 
              key={index} 
              className="flex items-start"
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Check className="h-5 w-5 text-primary-400 mr-2 flex-shrink-0 mt-0.5" />
              <span className="text-white/90">{feature}</span>
            </motion.li>
          ))}
        </ul>

        {/* Action Button */}
        <motion.a
          href={actionUrl}
          className={cn(
            "block w-full py-4 rounded-full text-center",
            "transform transition-all duration-300",
            "hover:scale-105 active:scale-95",
            popular
              ? "bg-primary-500 text-white hover:bg-primary-600"
              : "bg-white/[0.08] text-white border border-primary-500/20 hover:border-primary-500/40"
          )}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {actionLabel}
        </motion.a>
      </div>
    </motion.div>
  );
};

export default PricingPackage;