import React from 'react';
import HeroTitle from './HeroTitle';
import HeroDescription from './HeroDescription';
import HeroButton from './HeroButton';

const HeroContent = () => {
  return (
    <div className="flex flex-col items-center justify-start space-y-8 md:space-y-10 max-w-4xl mx-auto">
      <HeroTitle />
      <HeroDescription />
      <HeroButton />
    </div>
  );
};

export default HeroContent;