import React from 'react';
import PortfolioFilter from './portfolio/components/PortfolioFilter';
import PortfolioGrid from './portfolio/components/PortfolioGrid';
import BackgroundComposer from './background/BackgroundComposer';
import { usePortfolioFilter } from './portfolio/hooks/usePortfolioFilter';

const Portfolio = () => {
  const { activeFilter, filteredProjects, handleFilterChange } = usePortfolioFilter();

  return (
    <section id="portfolio" className="py-32 bg-dark relative overflow-hidden">
      <BackgroundComposer
        enableVanta={false}
        enableParticles={true}
        enableOrbs={true}
        intensity="medium"
      >
        <div className="container mx-auto px-4 relative z-10">
          <div className="text-center mb-16">
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Featured Projects
            </h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Discover our innovative solutions that have helped businesses transform 
              their digital presence and achieve remarkable growth.
            </p>
          </div>

          <PortfolioFilter 
            activeFilter={activeFilter} 
            onFilterChange={handleFilterChange}
          />

          <PortfolioGrid projects={filteredProjects} />
        </div>
      </BackgroundComposer>
    </section>
  );
};

export default Portfolio;