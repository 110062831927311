import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '../utils/cn';

const teamMembers = [
  {
    name: 'Tesloch J. Kuey III',
    role: 'CEO, Head of Operations',
    image: 'https://www.35partners.com/assets/images/tesloch.png',
  },
  {
    name: 'Paul Ter Dup',
    role: 'Head of Business and Marketing',
    image: 'https://www.35partners.com/assets/images/paul.png',
  }
];

const Team = () => {
  return (
    <div className="space-y-16">
      {/* Header */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
        className="text-center"
      >
        <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
          Meet Our Team
        </h2>
        <p className="text-xl text-gray-300 max-w-3xl mx-auto">
          Meet the Dedicated Experts Driving Innovation at ThirtyFive Studio
        </p>
      </motion.div>

      {/* Team Grid */}
      <div className="grid md:grid-cols-2 gap-16 max-w-4xl mx-auto">
        {teamMembers.map((member, index) => (
          <motion.div
            key={member.name}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: index * 0.2 }}
            className="group text-center"
          >
            {/* Image */}
            <div className="relative w-72 h-72 mx-auto mb-6 rounded-2xl overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-b from-primary-500/20 via-primary-500/10 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-500 blur-2xl" />
              <img
                src={member.image}
                alt={member.name}
                className={cn(
                  "w-full h-full object-cover",
                  "transform transition-all duration-700",
                  "group-hover:scale-105"
                )}
              />
            </div>

            {/* Content */}
            <motion.h3 
              className="text-xl font-bold text-white mb-1 group-hover:text-primary-400 transition-colors duration-300"
              whileHover={{ x: 4 }}
            >
              {member.name}
            </motion.h3>
            <p className="text-primary-400/80 text-base font-medium">
              {member.role}
            </p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Team;