import React from 'react';
import { motion } from 'framer-motion';
import { ProjectImage } from './ProjectImage';
import { ProjectContent } from './ProjectContent';
import { Project } from '../../types';
import { cn } from '../../../../utils/cn';

interface ProjectCardProps {
  project: Project;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
  return (
    <motion.div
      whileHover={{ y: -8 }}
      className={cn(
        "group relative overflow-hidden",
        "bg-dark/50 backdrop-blur-sm rounded-xl",
        "border border-primary-600/20 hover:border-primary-600/40",
        "transition-all duration-500",
        "shadow-lg shadow-primary-900/20",
        "hover:shadow-xl hover:shadow-primary-900/30"
      )}
    >
      {/* Enhanced background glow effect */}
      <div className={cn(
        "absolute inset-0 opacity-0 group-hover:opacity-100",
        "bg-gradient-to-br from-primary-600/10 via-transparent to-transparent",
        "transition-opacity duration-500"
      )} />

      {/* Content */}
      <div className="relative">
        <ProjectImage 
          image={project.image} 
          title={project.title} 
          category={project.category}
        />
        <ProjectContent project={project} />
      </div>
    </motion.div>
  );
};

export default ProjectCard;