import React from 'react';
import { motion } from 'framer-motion';
import { ProjectCategory } from '../types';
import { cn } from '../../../utils/cn';

interface PortfolioFilterProps {
  activeFilter: ProjectCategory;
  onFilterChange: (filter: ProjectCategory) => void;
}

const PortfolioFilter: React.FC<PortfolioFilterProps> = ({ 
  activeFilter, 
  onFilterChange 
}) => {
  const filters: ProjectCategory[] = ['All', 'Website', 'Mobile App'];

  return (
    <div className="flex flex-wrap justify-center gap-4 mb-12">
      {filters.map((filter) => (
        <motion.button
          key={filter}
          onClick={() => onFilterChange(filter)}
          className={cn(
            "px-8 py-3 rounded-full text-sm font-medium",
            "transition-all duration-300 transform",
            "hover:scale-105 active:scale-95",
            "border-2",
            activeFilter === filter
              ? "bg-primary-500 text-white border-primary-500"
              : "bg-dark/50 text-gray-300 border-primary-500/20 hover:border-primary-500/40"
          )}
          whileHover={{ y: -2 }}
          whileTap={{ y: 0 }}
        >
          {filter}
        </motion.button>
      ))}
    </div>
  );
};

export default PortfolioFilter;