import React from 'react';
import AboutHero from './about/AboutHero';
import Mission from './about/Mission';
import Values from './about/Values';
import Team from './Team';
import BackgroundComposer from './background/BackgroundComposer';

const About = () => {
  return (
    <section id="about" className="py-16 bg-dark-900 relative overflow-hidden">
      <BackgroundComposer intensity="low">
        <div className="container mx-auto px-4">
          <div className="space-y-16 max-w-6xl mx-auto">
            <AboutHero />
            <Mission />
            <Values />
            <Team />
          </div>
        </div>
      </BackgroundComposer>
    </section>
  );
};

export default About;