import React from 'react';
import { motion } from 'framer-motion';
import BackgroundComposer from './background/BackgroundComposer';
import ServiceGrid from './services/ServiceGrid';

const Services = () => {
  return (
    <section id="services" className="py-24 bg-dark-900 relative overflow-hidden">
      <BackgroundComposer intensity="low" colorMode="default">
        <div className="container mx-auto px-4 relative z-10">
          <motion.div
            className="text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Our Services
            </h2>
            <p className="text-xl text-white/80 max-w-3xl mx-auto">
              From custom website design to strategic marketing and operations optimization, 
              our services are designed to meet your unique needs and help you succeed online.
            </p>
          </motion.div>

          <ServiceGrid />
        </div>
      </BackgroundComposer>
    </section>
  );
};

export default Services;