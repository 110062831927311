import React from 'react';
import { motion } from 'framer-motion';
import PricingPackage from './packages/PricingPackage';
import BackgroundComposer from './background/BackgroundComposer';
import { cn } from '../utils/cn';

const packages = [
  {
    title: 'Starter Package',
    price: '1,449.99',
    description: 'Perfect for small businesses or personal brands launching their first website.',
    features: [
      'Timeline: 1-2 Weeks',
      '1-5 Pages',
      'Custom Website Design',
      'Mobile & Desktop Responsive Design',
      'Basic Contact Form Integration',
      '1 Revision Round',
      '1 Month of Post-Launch Support',
      'Email & Phone Support'
    ],
    actionLabel: 'Start Your Project'
  },
  {
    title: 'Growth Package',
    price: '6,499.99',
    description: 'Ideal for growing businesses seeking to enhance their online presence.',
    features: [
      'Timeline: 3-5 Weeks',
      '6-10 Pages',
      'Advanced Custom Website Design',
      'Mobile & Desktop Responsive Design',
      'SEO-Optimized Pages',
      '2 Revision Rounds',
      '3 Months of Post-Launch Support',
      'Priority Support'
    ],
    popular: true,
    actionLabel: 'Grow with Us'
  },
  {
    title: 'Premium Package',
    price: '9,999.00',
    description: 'For established businesses needing a sophisticated website with advanced features.',
    features: [
      'Timeline: 8-11 Weeks',
      '11+ Pages',
      'Fully Customized Design & Development',
      'Advanced Features Integration',
      'SEO-Optimized Content & Analytics',
      '3+ Revision Rounds',
      'Ongoing Support Plan',
      'Priority Support'
    ],
    actionLabel: 'Schedule Consultation'
  }
];

const WebsitePackages = () => {
  return (
    <section id="packages" className="py-32 bg-dark relative overflow-hidden">
      <BackgroundComposer intensity="low">
        <div className="container mx-auto px-4 relative z-10">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Website Packages
            </h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Tailored solutions to establish and enhance your online presence. Choose the 
              package that best fits your business needs and goals.
            </p>
          </motion.div>

          <div className="grid lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
            {packages.map((pkg, index) => (
              <PricingPackage
                key={index}
                {...pkg}
              />
            ))}
          </div>

          <motion.div 
            className="mt-16 text-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <a 
              href="#contact" 
              className={cn(
                "inline-block text-primary-400 hover:text-primary-300",
                "transition-colors duration-300",
                "rounded-full px-6 py-3",
                "bg-primary-500/10 hover:bg-primary-500/20"
              )}
            >
              Need a custom solution? Let's discuss your requirements
            </a>
          </motion.div>
        </div>
      </BackgroundComposer>
    </section>
  );
};

export default WebsitePackages;