import React from 'react';
import { cn } from '../../utils/cn';
import ParticlesOverlay from './ParticlesOverlay';

interface BackgroundComposerProps {
  children: React.ReactNode;
  className?: string;
  intensity?: 'low' | 'medium' | 'high';
  colorMode?: 'default' | 'neon' | 'subtle';
}

const BackgroundComposer: React.FC<BackgroundComposerProps> = ({
  children,
  className,
  intensity = 'medium',
  colorMode = 'default'
}) => {
  return (
    <div className={cn("relative min-h-screen overflow-hidden", className)}>
      {/* Clean dark background */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-dark-900" />
      </div>
      
      {/* Subtle accent overlay */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-primary-500/5 via-transparent to-transparent opacity-30" />
      </div>
      
      {/* Minimal particle overlay */}
      <div className="absolute inset-0">
        <ParticlesOverlay
          quantity={15}
          color="primary-500"
          size="sm"
          speed="slow"
        />
      </div>
      
      {/* Content */}
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
};

export default BackgroundComposer;