import React from 'react';
import { motion } from 'framer-motion';
import ServiceCard from './ServiceCard';
import { servicesData } from './ServicesData';

const ServiceGrid = () => {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
      {servicesData.map((service, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: index * 0.1 }}
          className="h-full"
        >
          <ServiceCard {...service} />
        </motion.div>
      ))}
    </div>
  );
};

export default ServiceGrid;