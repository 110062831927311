import React from 'react';
import TrustedLogos from './TrustedLogos';
import { motion } from 'framer-motion';

const TrustedBy = () => {
  return (
    <div className="w-full bg-dark-900/50 backdrop-blur-sm py-8">
      <div className="container mx-auto px-4">
        <motion.div 
          className="text-center mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 1.2 }}
        >
          <p className="text-lg text-white/60 font-medium tracking-wide uppercase">
            Trusted by forward-thinking companies
          </p>
        </motion.div>

        <TrustedLogos />
      </div>
    </div>
  );
};

export default TrustedBy;