import React from 'react';
import { ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { cn } from '../../utils/cn';

const HeroButton = () => {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ 
        duration: 0.8,
        delay: 1,
        ease: "easeOut"
      }}
    >
      <motion.a
        href="#contact"
        className={cn(
          "group relative overflow-hidden",
          "inline-flex items-center gap-2 px-8 py-4",
          "bg-primary-500 text-white rounded-full", // Changed to rounded-full
          "hover:bg-primary-600 transition-all duration-300",
          "transform hover:translate-y-[-2px]",
          "shadow-lg shadow-primary-900/20",
          "hover:shadow-xl hover:shadow-primary-900/30"
        )}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {/* Button glow effect */}
        <div className="absolute inset-0 bg-gradient-to-r from-primary-400/0 via-primary-400/30 to-primary-400/0 -z-10 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000" />
        
        {/* Content */}
        <span className="font-medium relative z-10">Let's Create Something Amazing</span>
        <ArrowRight className="w-5 h-5 transform transition-transform group-hover:translate-x-1 relative z-10" />
        
        {/* Hover glow */}
        <div className="absolute inset-0 -z-20 bg-gradient-to-r from-primary-500/40 to-primary-400/40 opacity-0 group-hover:opacity-100 blur-xl transition-opacity duration-500" />
      </motion.a>
    </motion.div>
  );
};

export default HeroButton;