import React from 'react';
import { Facebook, Instagram, Mail, MapPin, Phone } from 'lucide-react';
import { motion } from 'framer-motion';
import { cn } from '../utils/cn';

const Footer = () => {
  const socialLinks = [
    { name: 'Facebook', href: 'https://www.facebook.com/profile.php?id=61572092622021', icon: Facebook },
    { name: 'Instagram', href: 'https://www.instagram.com/thirtyfivestudiohq/#', icon: Instagram },
  ];

  return (
    <footer className={cn(
      "bg-dark-800/80 backdrop-blur-md border-t border-primary-500/10",
      "text-white py-12"
    )}>
      <div className="container mx-auto">
        <div className="flex flex-col items-center">
          <div className="grid md:grid-cols-3 gap-8 w-full max-w-4xl mx-auto mb-8">
            <div className="text-center">
              <div className="flex items-center justify-center space-x-2 text-gray-300 hover:text-primary-400 transition-colors mb-2">
                <Mail className="h-5 w-5" />
                <a href="mailto:info@thirtyfivestudio.com">
                  info@thirtyfivestudio.com
                </a>
              </div>
            </div>

            <div className="text-center">
              <div className="flex items-center justify-center space-x-2 text-gray-300 hover:text-primary-400 transition-colors mb-2">
                <Phone className="h-5 w-5" />
                <a href="tel:+16512198788">
                  +1 (651) 219-8788
                </a>
              </div>
            </div>

            <div className="text-center">
              <div className="flex items-center justify-center space-x-2 text-gray-300 hover:text-primary-400 transition-colors mb-2">
                <MapPin className="h-5 w-5" />
                <span>Rochester, MN</span>
              </div>
            </div>
          </div>

          <div className="flex space-x-6 mb-8">
            {socialLinks.map((link) => {
              const Icon = link.icon;
              return (
                <motion.a
                  key={link.name}
                  href={link.href}
                  className="text-gray-300 hover:text-primary-400 transition-colors"
                  aria-label={link.name}
                  whileHover={{ scale: 1.2, rotate: 5 }}
                >
                  <Icon className="h-6 w-6" />
                </motion.a>
              );
            })}
          </div>

          <p className="text-gray-300">
            © 2025 ThirtyFive Studio, LLC. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
