import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '../utils/cn';

const TrustedLogos = () => {
  const logos = [
    {
      src: 'https://i.postimg.cc/dkGjtF03/viewpoints.png',
      alt: 'ViewPoints App',
    },
    {
      src: 'https://i.postimg.cc/kD83bYGd/digital.png',
      alt: 'Digital Solutions Pro',
    },
    {
      src: 'https://i.postimg.cc/hXDHNSrS/tech.png',
      alt: 'Tech Innovate',
    },
    {
      src: 'https://i.postimg.cc/DwTS2qq8/Group-1000002217.png',
      alt: 'Creative Tech',
    },
    {
      src: 'https://i.postimg.cc/bGWnKw1W/innovators.png',
      alt: 'Digital Innovators',
    },
    {
      src: 'https://i.postimg.cc/njmmZfkz/solutions.png',
      alt: 'Tech Solutions',
    },
  ];

  return (
    <div className="relative w-full overflow-hidden py-8">
      <div className="flex space-x-16 animate-scroll">
        {/* Double the logos for seamless infinite scroll */}
        {[...logos, ...logos].map((logo, index) => (
          <motion.div
            key={`logo-${index}`}
            className="flex-shrink-0 w-[200px] flex items-center justify-center"
            whileHover={{ scale: 1.05, y: -2 }}
          >
            <img
              src={logo.src}
              alt={logo.alt}
              className={cn(
                "h-[60px] w-auto object-contain",
                "opacity-60 hover:opacity-100",
                "transition-all duration-300",
                "filter grayscale hover:grayscale-0"
              )}
            />
          </motion.div>
        ))}
      </div>
      
      {/* Enhanced gradient fade edges */}
      <div className="absolute inset-y-0 left-0 w-32 bg-gradient-to-r from-dark/95 to-transparent z-10"></div>
      <div className="absolute inset-y-0 right-0 w-32 bg-gradient-to-l from-dark/95 to-transparent z-10"></div>
    </div>
  );
};

export default TrustedLogos;
